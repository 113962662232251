export const ignoreParentSwipeableConfig = {
    onSwiped: (eventData) => {
        eventData.event.stopPropagation();
    },
    onSwipedLeft: (eventData) => {
        eventData.event.stopPropagation();
    },
    onSwipedRight: (eventData) => {
        eventData.event.stopPropagation();
    },
    onSwipedUp: (eventData) => {
        eventData.event.stopPropagation();
    },
    onSwipedDown: (eventData) => {
        eventData.event.stopPropagation();
    },
}
import classes from './MobilePlayerCard.module.scss';
import Skeleton from 'react-loading-skeleton';
import FeatherIcon from 'feather-icons-react';
import Button from 'components/ui/Button';
import PositionColor from 'components/ui/PositionColor';


function MobilePlayerCard (props) {
    const {
        player,
        isLoading = false,
        isFaded = false,
        isLinked = false,
        showAddButton = false,
        showGiveButton = false,
        showGiveRemoveButton = false,
        showGetButton = false,
        showGetRemoveButton = false,
        onAdd = () => {},
        onGive = () => {},
        onGiveRemove = () => {},
        onGet = () => {},
        onGetRemove = () => {},
        showRemoveButton = false,
        onRemove = () => {},
        clickAnywhere = false,
    } = props;

    const { displayName = '', teamAbbreviation='', position='', value=0 } = player;

    function onClickHandler() {
        if (clickAnywhere) {
            if (showAddButton) { return onAdd() }
            if (showRemoveButton) { return onRemove() }
        }
    }

    return (
        <div className={`${classes.playerCard} ${isFaded ? classes.faded : ''}`} onClick={onClickHandler}>
            {isLoading ? <Skeleton baseColor='rgba(0,0,0,0)' highlightColor='#B8B8B8' containerClassName={classes.skeletonContainer} /> : (<>
                    <div className={classes.headshotContainer}>
                        <img
                            draggable="false"
                            src={player.headshotHref}
                            alt={player.displayName}
                        />
                    </div>
                    <span className={classes.name}>
                        <span>{displayName}</span>
                    </span>
                    <span className={classes.details}>
                        <span style={{whiteSpace: 'nowrap'}}>{teamAbbreviation} |</span><span className={classes.position}><PositionColor position={position}>{position.toUpperCase()}</PositionColor></span>
                    </span>
                <div className={classes.rightmostBlock}>
                    {
                        isLinked && (
                            <FeatherIcon
                                icon="link"
                                stroke="#FCA311"
                                height="2.5rem"
                                width="2.5rem"
                            />
                        )
                    }
                    <div className={classes.value}>
                        {value}
                    </div>
                    {showAddButton && (
                        <Button type="transluscent" solid onClick={onAdd}>
                            <FeatherIcon
                                icon="user-plus"
                                stroke="white"
                                height="3rem"
                                width="3rem"
                            />
                        </Button>)
                    }
                    {showGiveButton && (
                        <Button type="outline" onClick={onGive}>
                            <span className={classes.giveGetButton}>Give</span>
                        </Button>)
                    }
                    {showGiveRemoveButton && (
                        <Button type="transluscent" onClick={onGiveRemove}>
                            <span className={classes.giveGetRemoveButton}>Give</span>
                        </Button>)
                    }
                    {showGetButton && (
                        <Button type="outline" onClick={onGet}>
                            <span className={classes.giveGetButton}>Get</span>
                        </Button>)
                    }
                    {showGetRemoveButton && (
                        <Button type="transluscent" onClick={onGetRemove}>
                            <span className={classes.giveGetRemoveButton}>Get</span>
                        </Button>)
                    }
                    {showRemoveButton && (
                        <Button type="transluscent" solid onClick={onRemove}>
                            <FeatherIcon
                                icon="trash-2"
                                stroke="white"
                                height="3rem"
                                width="3rem"
                            />
                        </Button>)
                    }
                </div>
                
            </>)}
        </div>
    )
}

export default MobilePlayerCard;
import classes from './Layout.module.scss';
import MobileNavigation from 'components/layout/MobileNavigation';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { applyTheme } from 'utils/applyTheme';
import { debounce } from 'lodash';

function Layout(props) {
    const globalTheme = useSelector((state) => state.theme.globalTheme);
    useEffect(() => {
        applyTheme(globalTheme);
    },[globalTheme]);

    const [layoutHeight, setLayoutHeight] = useState(document.documentElement.clientHeight)
    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            console.log('handle resize');
            setLayoutHeight(document.documentElement.clientHeight);
        }, 150)
    
        window.addEventListener('resize', debouncedHandleResize)
    
        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)
        }
    })

    return (
        <div className={classes.layout} style={{
            height: layoutHeight
        }}>
            <MobileNavigation />
            <main className={classes.main}>{props.children}</main>
        </div>
    );
}

export default Layout;

import classes from './MobilePlayerSelector.module.scss';
import { useEffect, useState, useRef } from 'react';

import MobilePlayerCard from './MobilePlayerCard';
import Search from 'components/ui/Search';
import useDebounce from 'utils/use-debounce';
import useOutsideClick from 'utils/useOutsideClick';
import { POSITIONS } from 'constants/positions';

import VirtualScroll from 'react-dynamic-virtual-scroll';
import { Searcher as FuzzySearch } from 'fast-fuzzy';
import FeatherIcon from 'feather-icons-react';
import { Slider, Rail, Handles } from "react-compound-slider";
import { get } from 'lodash';

function MobilePlayerSelector (props) {
    const {
        teams,
        fullPlayerList,
        loadingPlayers,
        onAddPlayer = () => {},
        onRemovePlayer = () => {},
        isOpen = false,
        setIsOpen = () => {},
    } = props;

    const [searchFilter, setSearchFilter] = useState('');
    const [positionsFilter, setPositionsFilter] = useState({});
    const [valueFilter, setValueFilter] = useState([100]);
    const debouncedValueFilter = useDebounce(valueFilter, 150);
    const [valueFilterRange, setValueFilterRange] = useState([0, 100]);
    const [availablePlayers, setAvailablePlayers] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [numFilters, setNumFilters] = useState(0);

    function togglePositionFilter (position) {
        const currentSetting = positionsFilter[position];
        setPositionsFilter({...positionsFilter, [position]: !currentSetting});
    }

    const selectedPositions = Object.keys(positionsFilter).filter(key => !!positionsFilter[key]);

    const wrapperRef = useRef(null);

    function handleClose () {
        setIsOpen(false);
    }

    useOutsideClick(wrapperRef, handleClose);

    useEffect(() => {
        let filteredPlayers = fullPlayerList.filter((player) => {
            if (selectedPositions.length && !positionsFilter[get(player, 'position').toLowerCase()]) {
                return false;
            }

            const playerValue = parseFloat(player.value);
            if (playerValue > debouncedValueFilter[0]) return false;

            return true;
        });

        if (searchFilter && filteredPlayers.length) {
            const searcher = new FuzzySearch(filteredPlayers, { keySelector: (p) => p.displayName, threshold: .8 });
            filteredPlayers = searcher.search(searchFilter);
        }

        setAvailablePlayers(filteredPlayers);
    }, [searchFilter, fullPlayerList, positionsFilter, debouncedValueFilter, selectedPositions.length]);

    const sliderStyle = {
        position: 'relative',
        width: '100%',
        touchAction: 'none',
    };

    useEffect(() => {
        const maxValue = Math.ceil(parseFloat(get(fullPlayerList[0], 'value', 100)));

        setValueFilterRange([0, maxValue]);
        setValueFilter([maxValue]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[fullPlayerList])

    function handleSliderUpdate(v) {
        setValueFilter(v)
    }

    useEffect(() => {
        let appliedFilterCount = 0;

        if (valueFilter[0] < valueFilterRange[1]) appliedFilterCount++;
        if (selectedPositions.length) appliedFilterCount++;
        if (appliedFilterCount > 0) return setNumFilters(`${appliedFilterCount}`);
        setNumFilters('');
    },[selectedPositions, valueFilter, valueFilterRange]);

    return (
        <div ref={wrapperRef} className={classes.playerSelectorWrapper}>
            <div className={`${classes.playerSelector} ${isOpen ? classes.open : ''}`}>
                <button className={`${classes.filterButton} ${numFilters > 0 ? classes.filtersApplied : ''}`} onClick={() => setShowFilters(!showFilters)}>
                    <FeatherIcon
                        icon='filter'
                        width="3.5rem"
                        height="3.5rem"
                    />
                    <div className={classes.filterButtonText}>{numFilters}</div>
                </button>
                <Search 
                    className={classes.searchBar}
                    inputClassName={classes.searchBarInput}
                    onSearch={(value) => {setSearchFilter(value)}}
                    debounce={350}
                    placeholder='Find a player...' 
                    onFocus={() => setIsOpen(true)} />
                <button className={classes.closeButton} onClick={() => setIsOpen(false)}>&times;</button>
                <div className={`${classes.filters} ${showFilters ? classes.filtersExpanded : ''}`}>
                    <div className={classes.positionButtons}>
                        {
                            POSITIONS.map(pos => {
                                return <button key={pos}
                                            className={`${classes[pos.toLowerCase()]} ${positionsFilter[pos] ? classes.active : ''}`}
                                            onClick={() => {togglePositionFilter(pos)}}>
                                                <div className={classes.positionButtonText}>{pos.toUpperCase()}</div>
                                        </button>
                            })
                        }
                    </div>
                    <div className={classes.sliderWrapper}>
                        <Slider
                            mode={1}
                            step={1}
                            rootStyle={sliderStyle}
                            domain={valueFilterRange}
                            onUpdate={handleSliderUpdate}
                            onChange={handleSliderUpdate}
                            values={valueFilter} >
                            <Rail>
                            {({ getRailProps }) => (
                                <div className={classes.sliderRail} {...getRailProps()} />
                            )}
                            </Rail>
                            <Handles>
                                {({ handles, getHandleProps }) => (
                                <div>
                                    { handles.map((handle) => {
                                        const [min, max] = valueFilterRange;
                                        const { id, value, percent } = handle;
                                        return <div 
                                            className={classes.sliderHandle}
                                            key={id}
                                            role="slider"
                                            aria-valuemin={min}
                                            aria-valuemax={max}
                                            aria-valuenow={value}
                                            style={{ left: `calc((${percent} / 100) * (100% - 3rem) + 1.5rem)` }}
                                            {...getHandleProps(id)}>
                                                <div className={classes.sliderHandleVisual} />
                                            </div>
                                    })}
                                </div>
                                )}
                            </Handles>
                        </Slider>
                        <div className={classes.sliderValue}>
                            <span className={classes.sliderValueText}>{valueFilter}</span>
                        </div>
                    </div>
                </div>
                <VirtualScroll
                    className={classes.playerList}
                    minItemHeight={40}
                    totalLength={availablePlayers.length}
                    renderItem={(rowIndex) => {
                        const player = availablePlayers[rowIndex];
                        const alreadyGive = teams[0].players[player.id];
                        const alreadyGet = teams[1].players[player.id];
                        return <div className={classes.playerCardListItem}>
                            <MobilePlayerCard
                                key={player.id}
                                player={player}
                                isLoading={loadingPlayers}
                                showGiveButton={!alreadyGive}
                                showGiveRemoveButton={alreadyGive}
                                onGive={() => {onAddPlayer(0, player)}}
                                onGiveRemove={() => {onRemovePlayer(0, player)}}
                                showGetButton={!alreadyGet}
                                showGetRemoveButton={alreadyGet}
                                onGet={() => {onAddPlayer(1, player)}}
                                onGetRemove={() => {onRemovePlayer(1, player)}}/>
                        </div>
                    }}
                />
            </div>
        </div>
    );
}

export default MobilePlayerSelector;
import classes from './MobileTeamValues.module.scss';
import { useState, useEffect } from 'react';

import { computeTeamDiffs } from 'utils/tradeEvaluation';
import DiffNumber from 'components/trade-builder/DiffNumber';
import DiffBar from 'components/ui/DiffBar';
import { POSITIONS } from 'constants/positions';

import { get } from 'lodash';

function getEvaluateDescription(givingDiff, totalValue) {
    const averageTradedValue = totalValue / 2;
    const evenLimit = Math.max(1, averageTradedValue * 0.03);
    const heavyLimit = Math.min(averageTradedValue * 0.3, 25);
    if (givingDiff > heavyLimit) return 'You are heavily favored in this trade.';
    if (givingDiff > evenLimit) return 'You are favored in this trade.';
    if (givingDiff < -heavyLimit) return 'You lose a lot of value in this trade.';
    if (givingDiff < -evenLimit) return 'You lose value in this trade.';
    return 'This is roughly an even trade.';
}

function MobileTeamValues(props) {
    const {
        teams,
    } = props;

    const teamDiffs = computeTeamDiffs(teams);
    const largestDiff = Math.max(...POSITIONS.map(pos => {
        return Math.abs(get(teamDiffs[0], `diffs.${pos}`, 0));
    }));
    const positionBarDenominator = largestDiff;

    const [evaluateDescription, setEvaluateDescription] = useState('');
    useEffect(() => {
        const totalValueTraded = get(teamDiffs[0], 'value.total', 0) + get(teamDiffs[1], 'value.total', 0);
        const team1diff = get(teamDiffs[0], 'diffs.total', 0);
        setEvaluateDescription(getEvaluateDescription(team1diff, totalValueTraded));
    }, [teamDiffs]);

    return (
        <div className={classes.tradeEvaluation}>
            <div className={classes.dividerExtension}/>
                <div className={classes.dividerBox}>
                    <div className={classes.totalDiff}><DiffNumber value={get(teamDiffs[0], 'diffs.total', 0)} size='large' /></div>
                    <span className={classes.evaluateDescription}>{evaluateDescription}</span>
                </div>
            <div className={classes.dividerChart}>
                <div className={classes.positionBars}>
                    {
                        POSITIONS.map(pos => {
                            return <div className={classes.positionBarWrapper} key={pos}>
                                <DiffBar value={get(teamDiffs[0], `diffs.${pos}`, 0)} denominator={positionBarDenominator} position={pos}/>
                            </div>
                        })
                    }
                </div>
            </div>
            <div className={classes.dividerExtension}/>
        </div>
    );
}

export default MobileTeamValues;
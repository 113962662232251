import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import axios from 'axios';
import { cloneDeep, get, isEmpty } from 'lodash';
import classes from './TradeBuilder.module.scss';
import MobileTradeBuilder from 'components/mobile-trade-builder/MobileTradeBuilder';
import { POSITIONS } from 'constants/positions';
import { setPprKey, setDataSource, setNumQbs } from 'store/players';
import { isScoringKeySupported } from 'utils/scoringKeys';

const defaultTeamNames = ['Giving', 'Getting'];

function TradeBuilderPage() {
    const [playerMap, setPlayerMap] = useState({});
    const [loadingPlayers, setLoadingPlayers] = useState(true);
    const pprKey = useSelector((state) => state.players.pprKey);
    const dataSource = useSelector((state) => state.players.dataSource);
    const numQbs = useSelector((state) => state.players.numQbs);
    const dispatch = useDispatch();

    if (!pprKey) dispatch(setPprKey('full'));
    if (!dataSource) dispatch(setDataSource('fantasycalc'));
    if (!numQbs) dispatch(setNumQbs('1'));

    const location = useLocation();
    const navigate = useNavigate();

    function computePlayerValue(origPlayers) {
        const newPlayers = Object.keys(origPlayers).reduce((players, playerId) => {
            const player = cloneDeep(origPlayers[playerId]);
            const playerValues = get(player, `values.${dataSource}`, []);
            const valueRow = playerValues.find(row => {
                if (isScoringKeySupported('numQbs', dataSource) && row.numQbs !== numQbs) return false;
                if (isScoringKeySupported('ppr', dataSource) && row.ppr !== pprKey) return false;
                return true;
            });
            player.value = get(valueRow, 'value', '0.0');
            player.trend = '0';
            return { ...players, [playerId]: player };
        }, {});
        return newPlayers;
    }

    useEffect(() => {
        setPlayerMap(computePlayerValue(playerMap, pprKey));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pprKey, numQbs, dataSource]);

    const [teams, setTeams] = useState([
        { players: {}, name: defaultTeamNames[0] },
        { players: {}, name: defaultTeamNames[1] },
    ]);

    useEffect(() => {
        const urlQuery = new URLSearchParams(location.search);

        teams.forEach((team, index) => {
            const savedName = urlQuery.get(`team${index + 1}name`);
            if (savedName) {
                team.name = savedName;
            }

            const savedPlayers = urlQuery.get(`team${index + 1}players`);
            if (savedPlayers) {
                const players = {};
                savedPlayers.split(' ').forEach((playerKey) => {
                    players[playerKey] = get(playerMap, playerKey, {});
                });
                team.players = players;
            }
        });
        setTeams([...teams]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playerMap]);

    useEffect(() => {
        axios({
            url: '/api/v1/players',
            headers: {
                Accept: 'application/json',
            },
        })
            .then((resp) => {
                const retrievedPlayers = get(resp, 'data', {});
                setPlayerMap(computePlayerValue(retrievedPlayers, pprKey));
                setLoadingPlayers(false);
            })
            .catch((err) => {
                console.error(err);
                setLoadingPlayers(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const urlQuery = new URLSearchParams(location.search);
        teams.forEach((team, index) => {
            const { name, players } = team;

            if (isEmpty(name) || name === defaultTeamNames[index]) {
                urlQuery.delete(`team${index + 1}name`);
            } else {
                urlQuery.set(`team${index + 1}name`, name);
            }
            if (isEmpty(players)) {
                urlQuery.delete(`team${index + 1}players`);
            } else {
                const playerKeys = Object.keys(players).join(' ');
                urlQuery.set(`team${index + 1}players`, playerKeys);
            }
            navigate({ search: urlQuery.toString() }, { replace: true });

            const zeroValues = POSITIONS.reduce(
                (acc, position) => {
                    return { ...acc, [position]: 0 };
                },
                { total: 0 }
            );

            if (!isEmpty(playerMap)) {
                team.value = Object.values(players).reduce((acc, player) => {
                    const position = get(player, 'position', 'NA').toLowerCase();
                    const value = parseFloat(player.value);
                    return {
                        ...acc,
                        [position]: acc[position] + value,
                        total: acc.total + value,
                    };
                }, zeroValues);
            } else {
                team.value = zeroValues;
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teams]);

    return (
        <section className={classes.section}>
            <MobileTradeBuilder playerMap={playerMap} loadingPlayers={loadingPlayers} teams={teams} setTeams={setTeams} />
        </section>
    );
}

export default TradeBuilderPage;

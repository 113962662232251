import { useEffect, useState } from "react";

import { componentWillAppendToBody } from "react-append-to-body";
import classes from './Modal.module.scss';
import { useSwipeable } from "react-swipeable";
import { ignoreParentSwipeableConfig } from "utils/ignoreSwipeable";


function RawModal(props) {
    const {
        bgColor,
        children,
        className,
        closeOnBackdropClick = false,
        fullScreen,
        height,
        onClose,
        showBackdrop = true,
        title,
        width,
    } = props;

    const [show, setShow] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setShow(true)
        },10);
    },[]);

    const animationTimeMS = 200;
    const handleClose = () => {
        setShow(false);
        setTimeout(() => {
            onClose();
        }, animationTimeMS);
    }

    const handlers = useSwipeable(ignoreParentSwipeableConfig);

    const validBgColors = ['linen', 'white', 'blue-5','blue-6', 'blue-7', 'blue-8'];
    const bgColorClass = validBgColors.includes(bgColor) ? classes[bgColor] : '';

    const modalHeight = fullScreen ? '100vh' : height;
    const modalWidth = fullScreen ? '100vw' : width || '80vw';

    return (
        <div className={classes.modalWrapper} {...handlers}>
            {showBackdrop && <div 
                className={classes.backdrop} onClick={closeOnBackdropClick ? handleClose : undefined}
                style={{
                    backgroundColor: `rgba(0, 0, 0, ${show ? '0.25' : '0'})`,
                    transition: `all ${animationTimeMS / 1000}s linear`,
                }}
            />}
            <div className={`${classes.modalContent} ${bgColorClass} ${fullScreen ? classes.fullScreen : ''} ${className}`}
                style={{ 
                    ...(modalHeight ? {height: modalHeight} : {minHeight: '20vh'}), 
                    width: modalWidth,
                    transform: `scale(${show ? 1 : '0.7'})`,
                    transition: `all ${animationTimeMS / 1000}s ease-in-out`,
                    opacity: show ? '100%' : '0'
                }}>
                <button className={classes.closeButton} onClick={handleClose}>&times;</button>
                {title && <div className={classes.title}>{title}</div>}
                <div className={classes.modalBody} style={{
                    height: title ? 'calc(100% - 9rem)' : '100%'
                }}>{children}</div>
            </div>
        </div>
    );
}

const Modal = componentWillAppendToBody(RawModal);

export default Modal;

import { useEffect, useState, useRef } from 'react';
import useDebounce from 'utils/use-debounce';
import classes from './Search.module.scss';
import FeatherIcon from 'feather-icons-react';

function Search(props) {
    let { 
        onSearch, 
        debounce, 
        placeholder, 
        className, 
        inputClassName, 
        onFocus = () => {} } = props;
    placeholder = placeholder || 'Search...';

    const [searchTerm, setSearchTerm] = useState('');
    const isEmpty = searchTerm.length === 0;
    const inputRef = useRef(null);
    const debouncedSearchTerm = useDebounce(searchTerm, debounce);

    function clearValue () {
        setSearchTerm('');
        onSearch('');
        inputRef.current.focus();
    }

    useEffect(() => {
        onSearch(debouncedSearchTerm);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchTerm]);

    return (
        <div className={`${classes.searchContainer} ${className}`}>
            <div className={classes.searchIcon}>
                <FeatherIcon icon="search" size={18} />
            </div>
            <input
                ref={inputRef}
                className={`${classes.search} ${inputClassName}`}
                type="search"
                placeholder={placeholder}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onFocus={(e) => onFocus(e)}
            />
            <button className={`${classes.clearButton} ${!isEmpty ? classes.showClearButton : ''}`} onClick={() => clearValue()}>&times;</button>
        </div>
    );
}

export default Search;

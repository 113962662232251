import classes from './MobileTradeBuilder.module.scss';
import { useEffect, useState } from 'react';

import MobileTeamPlayers from './MobileTeamPlayers';
import MobileTeamValues from './MobileTeamValues';
import MobilePlayerSelector from './MobilePlayerSelector';
import Button from 'components/ui/Button';

import FeatherIcon from 'feather-icons-react';
import { cloneDeep } from 'lodash';

function MobileTradeBuilder (props) {
    const {
        loadingPlayers = false,
        playerMap = {},
        teams,
        setTeams
    } = props;

    const [fullPlayerList, setFullPlayerList] = useState([]);
    const [playerSelectorOpen, setPlayerSelectorOpen] = useState(false);
    
    useEffect(() => {
        setFullPlayerList(Object.entries(playerMap).map(([id, player]) => {
            return {id, value: 0, ...player}
        }).sort((a, b) => {
            return b.value - a.value;
        }));
    },[playerMap]);

    function handleAddPlayer (teamIndex, player) {
        const team = teams[teamIndex];
        const playerId = player.id;
        // remove player from other team if added previously
        teams.forEach((t, i) => {
            if (i !== teamIndex) {
                delete t.players[playerId];
            }
        });
        team.players[playerId] = player;
        setTeams(cloneDeep(teams));
    }

    function handleRemovePlayer (teamIndex, player) {
        const team = teams[teamIndex];
        const playerId = player.id;
        delete team.players[playerId];
        setTeams(cloneDeep(teams));
    }

    function onFlipAllPlayers () {
        const team1Original = cloneDeep(teams[0]);
        console.log('🚀 ~ onFlipAllPlayers ~ team1Original:', team1Original);
        teams[0].players = teams[1].players;
        teams[1].players = team1Original.players;
        setTeams(cloneDeep(teams));
        console.log('🚀 ~ onFlipAllPlayers ~ teams:', teams);
    }

    return (
        <div className={`${classes.tradeEvaluatorCard}`}>
            <MobilePlayerSelector
                teams={teams} 
                loadingPlayers={loadingPlayers}
                fullPlayerList={fullPlayerList} 
                onAddPlayer={(team, player) => {
                    handleAddPlayer(team, player);
                }}
                onRemovePlayer={(team, player) => {
                    handleRemovePlayer(team, player);
                }}
                isOpen={playerSelectorOpen}
                setIsOpen={val => setPlayerSelectorOpen(val)}/>
            <div className={classes.teamLists}>
                <div className={`${classes.team1Players}`}>
                    <MobileTeamPlayers 
                        bgColor='blue-6'
                        onRemovePlayer={(player) => handleRemovePlayer(0, player)}
                        onShowPlayerSelector={() => setPlayerSelectorOpen(true)}
                        teams={teams}
                        teamIndex={0}
                        loadingPlayers={loadingPlayers}
                        fullPlayerList={fullPlayerList}/>
                </div>
                <div className={`${classes.team2Players}`}>
                    <MobileTeamPlayers 
                        bgColor='blue-7'
                        onRemovePlayer={(player) => handleRemovePlayer(1, player)}
                        onShowPlayerSelector={() => setPlayerSelectorOpen(true)}
                        teams={teams}
                        teamIndex={1}
                        loadingPlayers={loadingPlayers}
                        fullPlayerList={fullPlayerList}/>
                </div>
            </div>
            <div className={classes.tradeEvaluator}>
                <div className={classes.tradeEvaluatorTitle}><span>Outcome</span></div>
                <div className={classes.tradeEvaluatorButtons}>
                    <Button className={classes.flipButton} type="transluscent" solid onClick={onFlipAllPlayers}>
                        <FeatherIcon
                            icon="refresh-ccw"
                            height="2.5rem"
                            width="2.5rem"
                        />
                    </Button>
                </div>
                <MobileTeamValues teams={teams}/>
            </div>
            
        </div>
    );
}

export default MobileTradeBuilder;
export function applyTheme (theme) {
    const root = document.querySelector(':root');
    switch(theme) {
        case 'light':
            root.setAttribute('data-theme', 'light');
        break;

        case 'dark':
            root.setAttribute('data-theme', 'dark');
        break;

        case 'blue-steel':
        default:
            root.setAttribute('data-theme', 'blue-steel');
    }
}

export const VALID_THEMES = [
    { id: 'light', label: 'Light' },
    { id: 'dark', label: 'Dark' },
    { id: 'blue-steel', label: 'Blue Steel'}
];
import { useEffect } from "react";

const useOutsideClick = (ref, callbackFn) => {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callbackFn();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, callbackFn]);
};

export default useOutsideClick;


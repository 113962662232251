import classes from './About.module.scss';

import Card from 'components/ui/Card';

import { Link } from 'react-router-dom';

function About() {
    return (
        <section className={classes.section}>
            <Link
                className={classes.closeButton}
                to={'/trade-values'}>&times;
            </Link>
            <Card header="About" backgroundColor='linen' className={classes.aboutCard}>
                <section className={classes.body}>
                <h3 id="overview">Overview</h3>
                <p>Trade values are a score computed from expert rankings, adjusted by real trade data pulled from thousands of public leagues. These values are better than projections and rankings for determining if a trade is balanced, for a few reasons:</p>
                <ul>
                    <li><p><strong>Trade values factor in the difference between positions.</strong> For example, a top 10 RB is more valuable than a top 10 QB, even though the QB will likely score more overall fantasy points.</p>
                    </li>
                    <li><p><strong>Trade values naturally adjust for off-the-field factors</strong>, like injury risk or trade uncertainty.</p>
                    </li>
                    <li><p><strong>Trade values capture the significance of startable players</strong>. The 15th-best QB or TE isn&#39;t worth very much in a league where you can only start one player in those positions.</p>
                    </li>
                </ul>
                <h3 id="trade-balancing">Trade Balancing</h3>
                <p>In general, a balanced trade has a value difference close to 0. This holds true in 2-for-2 scenarios, as well as 3-for-3 and so on. However, there are some situations in which a fair trade may give one side more value than the other:</p>
                <ul>
                    <li><p>If one side is receiving a single player with a much higher value than any other players in the trade, they may have to overpay in order to make the trade worthwhile.</p>
                    </li>
                    <li><p>When the trade involves unbalanced numbers of players (e.g. 1-for-2), the team that is consolidating value into a smaller number of players may have to overpay in value.</p>
                    </li>
                </ul>
                <h3 id="data-source">Data Source</h3>
                <p>Multiple data sources are provided for your selection.</p>
                <img align="left" className={classes.dataSourceLogo} src="/img/fantasy-calc-logo.png" alt="FantasyCalc.com logo"/><p><a href="https://fantasycalc.com/redraft-rankings">FantasyCalc.com</a> (default) calculates trade values from real trades in MFL and Sleeper leagues and updates multiple times daily. Pigskin Exchange retrieves the latest values twice a day. This data source supports advanced scoring rules, like superflex.</p>
                <img align="left" className={classes.dataSourceLogo} src="/img/peaked-logo.png" alt="PeakedInHighSkool logo"/><p><a href="https://peakedinhighskool.com/">PeakedInHighSkool</a> computes trade values from real trades in Yahoo leagues and Reddit self-reported trades on a weekly basis. Pigskin Exchange retrieves the latest data weekly on Wednesday mornings. Advanced scoring settings are only available to PeakedInHighSkool's <a href="https://www.patreon.com/Peakedinhighskool/posts">Patreon</a> subscribers, so they are not available in this trade calculator.</p>
                <p>Player data and photos are provided by ESPN.</p>
                <h3 id="developer">Developer</h3>
                <p>Developed using Node.js and React. Hosted on Firebase. Contact <a href="mailto:connellyguy@gmail.com">connellyguy@gmail.com</a> or <a href="https://www.reddit.com/user/ffdata_dev/">/u/ffdata_dev</a> on reddit for inquiries.</p>
                </section>
            </Card>
        </section>
    );
}

export default About;

export const PPR_KEYS = [
    {
        label: 'PPR',
        value: 'full',
    },
    {
        label: '0.5 PPR',
        value: 'half',
    },
    {
        label: 'STD',
        value: 'std',
    },
];

export const NUM_QBS_KEYS = [
    {
        label: 'Normal',
        value: '1'
    },
    {
        label: 'Superflex',
        value: '2'
    }
]

export const DATA_SOURCE_KEYS = [
    {
        label: 'FantasyCalc.com',
        value: 'fantasycalc',
    },
    {
        label: 'PeakedInHighSkool',
        value: 'peaked',
    }
]

export const SUPPORTED_SCORING_KEYS = {
    'fantasycalc': ['numQbs', 'ppr'],
    'peaked': ['ppr']
}

export const DATA_SOURCE_LINKS = {
    'fantasycalc': 'https://fantasycalc.com/redraft-rankings',
    'peaked': 'https://peakedinhighskool.com',
}
import { useEffect, useState, useRef } from "react";
import useOutsideClick from "utils/useOutsideClick";

import { componentWillAppendToBody } from "react-append-to-body";
import classes from './SlidePanel.module.scss';

function RawSlidePanel(props) {
    const {
        bgColor,
        children,
        closeOnBackdropClick = false,
        onClose,
        showBackdrop = true,
        topMargin = 0,
        width = '50rem',
        fullScreen = false,
    } = props;

    const panelRef = useRef(null);

    const [show, setShow] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setShow(true)
        },10);
    },[]);

    const animationTimeMS = 200;
    const handleClose = () => {
        setShow(false);
        setTimeout(() => {
            onClose();
        }, animationTimeMS);
    }

    useOutsideClick(panelRef, handleClose);

    const validBgColors = ['linen', 'white'];
    const bgColorClass = validBgColors.includes(bgColor) ? classes[bgColor] : '';

    const panelWidth = fullScreen ? '100vw' : width;

    return (
        <div className={classes.slidePanelWrapper}>
            {showBackdrop && <div 
                className={classes.backdrop} onClick={closeOnBackdropClick && handleClose}
                style={{
                    backgroundColor: `rgba(0, 0, 0, ${show ? '0.25' : '0'})`,
                    transition: `all ${animationTimeMS / 1000}s linear`,
                }}
            />}
            <div ref={panelRef} className={`${classes.slidePanel} ${bgColorClass}`}
                style={{
                    height: `calc(100vh - ${topMargin})`,
                    left: show ? '0' : `-${panelWidth}`,
                    top: topMargin,
                    transition: `all ${animationTimeMS / 1000}s ease-in-out`,
                    width: panelWidth,
                }}>
                <button className={classes.closeButton} onClick={handleClose}>&times;</button>
                {children}
            </div>
        </div>
    );
}

const SlidePanel = componentWillAppendToBody(RawSlidePanel);

export default SlidePanel;

import React, { useEffect, useReducer, useState } from 'react';
import classes from './DiffBar.module.scss';

import animateNumber from 'utils/animateNumber';

function computeBarHeight(diffValue, denominator, maxHeightInRem) {
    if (diffValue === 0) return 0;
    if (denominator === 0) return 0;
    return (diffValue / denominator * maxHeightInRem);
}

function barStyle(height) {
    if (height > 0) {
        return {
            height: `${height}rem`,
            bottom: '50%',
        }
    }

    return {
        height: `${-height}rem`,
        top: '50%',
    }
}

function DiffBar(props) {
    const {
        value,
        denominator,
        maxHeightInRem = 10,
        position = '',
    } = props;

    const barHeight = computeBarHeight(value, denominator, maxHeightInRem);

    const [displayHeight, setDisplayHeight] = useState(barHeight);
    const directionClass = displayHeight > 0 ? classes.positive : displayHeight < 0 ? classes.negative : '';

    function displayHeightReducer(state, action) {
        switch (action.type) {
            case 'update':
                animateNumber(setDisplayHeight, state.value, action.payload, 150);
                return { value: action.payload };
            default:
                throw new Error();
        }
    }

    const [, dispatchTotal] = useReducer(displayHeightReducer, { barHeight });
    useEffect(() => {
        dispatchTotal({ type: 'update', payload: barHeight });
    }, [barHeight]);

    return <div className={classes.diffBarWrapper}>
        <div style={barStyle(displayHeight)} className={`${classes.diffBar} ${directionClass} ${classes[position]}`}>
            <span className={classes.positionBarLabel}>{position.toUpperCase()}</span>
        </div>
    </div>
}

export default DiffBar;
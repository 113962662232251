export function computeTeamDiffs(teams) {
    return teams.map((team, index) => {
        if (!team.value)
            return {
                value: team.value || {},
                isWinner: false,
                isLoser: false,
                diffs: {},
            };

        const otherTeam = teams[1 - index];

        const diffs = Object.keys(team.value).reduce((acc, key) => {
            return { ...acc, [key]: otherTeam.value[key] - team.value[key] };
        }, {});

        const isWinner = diffs.total > 0;
        const isLoser = diffs.total < 0;

        return {
            value: team.value,
            diffs,
            isWinner,
            isLoser,
        };
    });
}
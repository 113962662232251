import classes from './Logo.module.scss';

function Logo(props) {
    const {
        className = '',
        onClick,
    } = props;

    const logoWords = ['PIGSKIN', 'EXCHANGE'];
    const hairSpace = String.fromCharCode(8202);
    logoWords[0] = logoWords[0].split('').join(hairSpace + hairSpace);

    return (
        <div className={`${classes.logo} ${className}`} onClick={onClick}>
            <div className={classes.firstWord}>{logoWords[0]}</div>
            <div className={classes.secondWord}>{logoWords[1]}</div>
        </div>
    )
}

export default Logo;
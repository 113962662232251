import classes from './ButtonRow.module.scss';
import { get } from 'lodash';

function ButtonRow(props) {
    const { 
        buttons = [],
        forceUpperCase = false,
        type = '', // dark, menu
        size = 'medium',
    } = props;

    const validSizes = ['small', 'medium', 'large'];
    const buttonSize = validSizes.includes(size) ? size : 'medium';

    // buttons:
    // [{
    //     action: () => {},
    //     value: 'string',
    //     label: 'string',
    //     isActive: true/false,
    // }]

    return (
        <div className={classes.buttonRow}>
            {
                buttons.map(btn => {
                    const { label = '' } = btn;
                    return <button key={btn.label}
                                className={`${btn.isActive ? classes.active : ''} ${get(classes, type, '')} ${get(classes, buttonSize, '')}`}
                                onClick={(e) => {
                                    e.currentTarget.blur();
                                    btn.action();
                                }}>
                                    <div className={classes.rowButtonText}>{ forceUpperCase ? label.toUpperCase() : label}</div>
                            </button>
                })
            }
        </div>
    )
}

export default ButtonRow;
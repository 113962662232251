import { createSlice } from '@reduxjs/toolkit';

export const themeSlice = createSlice({
    name: 'theme',
    initialState: {
        globalTheme: 'blue-steel',
    },
    reducers: {
        setGlobalTheme: (state, action) => {
            state.globalTheme = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setGlobalTheme } = themeSlice.actions;

export default themeSlice.reducer;
import React, { useEffect, useReducer, useState } from 'react';
import animateNumber from 'utils/animateNumber';
import classes from './DiffNumber.module.scss';

function DiffNumber(props) {
    const { value, size = 'small', theme = 'light' } = props;
    const [displayValue, setDisplayValue] = useState(value);

    function displayValuesReducer(state, action) {
        switch (action.type) {
            case 'update':
                animateNumber(setDisplayValue, state.value, action.payload, 250);
                return { value: action.payload };
            default:
                throw new Error();
        }
    }

    let valueSymbol = <>&plusmn;</>;
    if (displayValue > 0) valueSymbol = '+';
    if (displayValue < 0) valueSymbol = '-';

    const [, dispatchTotal] = useReducer(displayValuesReducer, { value });
    useEffect(() => {
        dispatchTotal({ type: 'update', payload: value });
    }, [value]);

    return (
        <React.Fragment>
            <span
                className={`${displayValue > 0 ? classes.winner : ''} ${displayValue < 0 ? classes.loser : ''} ${theme === 'dark' ? classes.dark : ''}`}>
                <span style={{fontWeight: '400'}}>{valueSymbol}</span>
                {Math.abs(displayValue).toFixed(1)}
            </span>
            {displayValue > 0 && (
                <i className={`${classes.arrowUp} ${size === 'small' ? classes.small : ''} ${theme === 'dark' ? classes.dark : ''}`} />
            )}
            {displayValue < 0 && (
                <i className={`${classes.arrowDown} ${size === 'small' ? classes.small : ''} ${theme === 'dark' ? classes.dark : ''}`} />
            )}
        </React.Fragment>
    );
}

export default DiffNumber;

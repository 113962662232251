import classes from './MobileTeamPlayers.module.scss';
import { useState, useEffect } from 'react';

import MobilePlayerCard from './MobilePlayerCard';
import Button from 'components/ui/Button';

function MobileTeamPlayers (props) {
    const {
        teams,
        teamIndex,
        loadingPlayers,
        onRemovePlayer,
        onShowPlayerSelector,
    } = props;

    const { 
        name = 'Team Name', 
        players = {},
    } = teams[teamIndex];

    const [sortedPlayerList, setSortedPlayerList] = useState([]);

    useEffect(() => {
        setSortedPlayerList(Object.entries(players).map(([id, player]) => {
            return {id, value: 0, ...player}
        }).sort((a, b) => {
            return b.value - a.value;
        }));
    }, [players])

    return (
        <div className={classes.teamPlayers}>
            <div className={classes.background}>
                <span className={classes.teamNameDisplay}>{name}</span>
                <div className={classes.playerListWrapper}>
                    <div className={classes.playerList}>
                    {
                        sortedPlayerList.length ? sortedPlayerList.map((player) => {
                            return <MobilePlayerCard 
                                        key={player.id}
                                        player={player}
                                        isLoading={loadingPlayers}
                                        showRemoveButton
                                        onRemove={() => {onRemovePlayer(player)}} />
                        }) : <div className={classes.emptyPlayerList} onClick={onShowPlayerSelector}>
                                <Button type="dark-transluscent" solid size="large" onClick={onShowPlayerSelector}>
                                    <span className={classes.emptyPlayerAddButtonText}>+ Add players</span>
                                </Button>
                            </div>
                    }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileTeamPlayers;
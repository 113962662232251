import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import classes from './MobileNavigation.module.scss';

import FeatherIcon from 'feather-icons-react';
import { get } from 'lodash';

import Modal from 'components/ui/Modal';
import SlidePanel from 'components/ui/SlidePanel';
import Logo from 'components/ui/Logo';
import ButtonRow from 'components/ui/ButtonRow';
import HelpModal from 'components/layout/HelpModal';
import Dropdown from 'components/ui/Dropdown';

import { NUM_QBS_KEYS, PPR_KEYS, DATA_SOURCE_KEYS, DATA_SOURCE_LINKS } from 'constants/scoring-keys';
import { setPprKey, setDataSource, setNumQbs } from 'store/players';
import { setGlobalTheme } from 'store/theme';
import { isScoringKeySupported } from 'utils/scoringKeys';

import { useMediaQuery } from 'react-responsive';
import { MID_SIZE_BREAK_POINT } from 'constants/break-points';
import { VALID_THEMES } from 'utils/applyTheme';

function MainNavigation() {
    const navLinks = [
        {
            label: 'Trade calculator',
            path: '/trade-values',
        },
        {
            label: 'About',
            path: '/about',
        },
    ];

    const isMobile = useMediaQuery({ query: `(max-width: ${ MID_SIZE_BREAK_POINT })` });

    const menuRef = useRef(null);

    const location = useLocation();
    const path = location.pathname;
    const currentLocation = navLinks.find(link => link.path === path);

    const [showSettings, setShowSettings] = useState(false);
    const onClickSettings = () => {setShowSettings(true)};

    const [showMenu, setShowMenu] = useState(false);

    const [showHelp, setShowHelp] = useState(false);
    const onClickHelp = () => {setShowHelp(true)};

    const pprKey = useSelector((state) => state.players.pprKey);
    const dataSource = useSelector((state) => state.players.dataSource);
    const dataSourceExternalLink = get(DATA_SOURCE_LINKS, dataSource, '');
    const numQbs = useSelector((state) => state.players.numQbs);
    const globalTheme = useSelector((state) => state.theme.globalTheme);
    const dispatch = useDispatch();

    const [pprKeyButtons, setPprKeyButtons] = useState([]);
    const [numQbsButtons, setNumQbsButtons] = useState([]);
    const [themeButtons, setThemeButtons] = useState([]);

    useEffect(() => {
        setPprKeyButtons(PPR_KEYS.map(key => {
            return {
                value: key.value,
                label: key.label,
                action: () => {dispatch(setPprKey(key.value))},
                isActive: pprKey === key.value,
            }
        }));
    },[pprKey, dispatch]);

    useEffect(() => {
        setNumQbsButtons(NUM_QBS_KEYS.map(key => {
            return {
                value: key.value,
                label: key.label,
                action: () => {dispatch(setNumQbs(key.value))},
                isActive: numQbs === key.value,
            }
        }));
    },[numQbs, dispatch]);

    useEffect(() => {
        setThemeButtons(VALID_THEMES.map(theme => {
            return {
                value: theme.id,
                label: theme.label,
                action: () => {
                    console.log('setting theme: ', theme);
                    console.log('global theme: ', globalTheme);
                    dispatch(setGlobalTheme(theme.id))
                },
                isActive: globalTheme === theme.id
            }
        }))
    },[globalTheme, dispatch]);

    const topBarHeight = '8rem';

    return (
        <header
            className={classes.topBar}
            style={{ backgroundImage: 'url(/img/football-texture.png)', height: {topBarHeight} }}
            >
            <div className={`${ classes.leftAlign }`}>
                <Logo className={classes.logo} />
                <div className={classes.location}><span>{get(currentLocation, 'label', '')}</span></div>
            </div>
            <div className={`${ classes.rightAlign }`}>
                <button className={classes.helpIcon} onClick={onClickHelp}>
                    <FeatherIcon
                        icon="help-circle"
                        stroke="white"
                        height="3.5rem"
                        width="3.5rem"
                    />
                </button>
                <button className={classes.settingsIcon} onClick={onClickSettings}>
                    <FeatherIcon
                        icon="settings"
                        stroke="white"
                        height="3.5rem"
                        width="3.5rem"
                    />
                </button>
            </div>
            {showSettings && 
                <Modal 
                    bgColor="linen"
                    title="Settings"
                    width='fit-content(100%)'
                    onClose={() => {setShowSettings(false)}}>
                        <div className={classes.settingsList}>
                            <div className={classes.settingsRow}>
                                <span className={classes.settingsLabel}>Data Source:</span>
                                <Dropdown
                                    color='brown'
                                    options={DATA_SOURCE_KEYS}
                                    value={dataSource}
                                    onNewValue={(value) => {
                                        dispatch(setDataSource(value));
                                    }}
                                />
                                {dataSourceExternalLink && <a href={dataSourceExternalLink} className={classes.sourceLinkIcon} target="_blank" rel="noreferrer">
                                    <FeatherIcon
                                        icon="external-link"
                                        height="2.5rem"
                                        width="2.5rem"
                                    />
                                </a>}
                            </div>
                            <div className={`${classes.settingsRow} ${classes.vertical}`}>
                                <span className={classes.settingsLabel}>Scoring:</span>
                                <ButtonRow type='menu' size='small' buttons={pprKeyButtons} />
                                {isScoringKeySupported('numQbs', dataSource) && <ButtonRow type='menu' size='small' buttons={numQbsButtons} />}
                            </div>
                            <div className={`${classes.settingsRow} ${classes.vertical}`}>
                                <span className={classes.settingsLabel}>Theme:</span>
                                <ButtonRow type='menu' size='small' buttons={themeButtons} />
                            </div>
                        </div>
                </Modal>}
            {showHelp && <HelpModal onCloseModal={() => {setShowHelp(false)}} />}
            {showMenu && <SlidePanel ref={menuRef} bgColor="linen" topMargin={topBarHeight} showBackdrop={false} fullScreen={isMobile} onClose={() => {setShowMenu(false)}}>
                <nav className={classes.navLinks}>
                    {navLinks.map((navLink) => {
                        return (
                            <Link
                                key={navLink.path}
                                className={path === navLink.path ? classes.active : ''}
                                to={navLink.path}
                                onClick={() => {setShowMenu(false)}}>
                                {navLink.label}
                            </Link>
                        );
                    })}
                </nav>
            </SlidePanel>}
        </header>
    );
}

export default MainNavigation;

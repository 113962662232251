import { createSlice } from '@reduxjs/toolkit';

export const playersSlice = createSlice({
    name: 'players',
    initialState: {
        dataSource: 'fantasycalc',
        numQbs: '1',
        pprKey: 'full',
    },
    reducers: {
        setPprKey: (state, action) => {
            state.pprKey = action.payload;
        },
        setDataSource: (state, action) => {
            state.dataSource = action.payload;
        },
        setNumQbs: (state, action) => {
            state.numQbs = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setPprKey, setDataSource, setNumQbs } = playersSlice.actions;

export default playersSlice.reducer;

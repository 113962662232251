import Modal from 'components/ui/Modal';
import classes from './HelpModal.module.scss';

import { Link } from 'react-router-dom';

function HelpModal (props) {
    const {
        onCloseModal
    } = props;

    return (
        <Modal 
            bgColor="linen"
            title="Help"
            width='fit-content(100%)'
            onClose={onCloseModal}>
                <div className={classes.helpInfo}>
                    <p>Trade values are a score computed from expert rankings, adjusted by real trade data pulled from thousands of public leagues. A balanced trade should have each team give roughly equal total value. These values are better than projections and rankings for determining if a trade is balanced, for a few reasons:</p>
                    <ul>
                        <li><p><strong>Trade values factor in the difference between positions.</strong></p>
                        </li>
                        <li><p><strong>Trade values naturally adjust for off-the-field factors.</strong></p>
                        </li>
                        <li><p><strong>Trade values capture the significance of startable players</strong></p>
                        </li>
                    </ul>
                    <p>For more information, visit our <Link to={'/about'} onClick={onCloseModal}>about page</Link>.</p>
                </div>
        </Modal>
    );
}

export default HelpModal;